import React from 'react';
import './Advertisers.css';

const Advertisers = () => {
  return (
    <div className="advertisers">
      <h2>Quản lý Advertisers</h2>
      {/* Thêm thông tin Advertisers tại đây */}
    </div>
  );
};

export default Advertisers;
