import React from 'react';
import './SSP.css';

function SSP() {
  return (
    <div className="ssp">
      <h2>Quản lý SSP</h2>
      <p>Thông tin chi tiết về SSP...</p>
    </div>
  );
}

export default SSP;
