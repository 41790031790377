// src/contexts/AdContext.js
import React, { createContext, useState } from 'react';

// Tạo context
const AdContext = createContext();

// Tạo provider cho context
const AdProvider = ({ children }) => {
    const [ads, setAds] = useState([]); // Giá trị mặc định là một mảng rỗng

    return (
        <AdContext.Provider value={{ ads, setAds }}>
            {children}
        </AdContext.Provider>
    );
};

export { AdContext, AdProvider };
