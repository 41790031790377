// src/components/Footer.js
import React from 'react';
import './Footer.css'; // Tạo file CSS riêng nếu cần

const Footer = () => {
    return (
        <footer className="app-footer">
            <p>&copy; {new Date().getFullYear()} Ad Management. All rights reserved.</p>
        </footer>
    );
};

export default Footer;
