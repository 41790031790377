// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dsp {
  padding: 20px;
}

.dsp h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #34495e;
}

.dsp p {
  font-size: 16px;
  color: #7f8c8d;
}
`, "",{"version":3,"sources":["webpack://./src/components/DSP/DSP.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".dsp {\n  padding: 20px;\n}\n\n.dsp h2 {\n  font-size: 24px;\n  margin-bottom: 20px;\n  color: #34495e;\n}\n\n.dsp p {\n  font-size: 16px;\n  color: #7f8c8d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
