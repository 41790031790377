import React from 'react';

const AdSchedule = ({ adSchedule, handleViewClick, zoneId }) => {
    return (
        <div className="ad-schedule-section">
            <h3>Schedule: {zoneId === '53' ? 'Quốc Hội' : 'ANTV'}</h3>
            <div className="ad-schedule-list">
                {adSchedule.length > 0 ? (
                    adSchedule.map((schedule, index) => (
                        <div
                            key={index}
                            className="ad-schedule-item"
                            style={{ backgroundColor: 'green' }}
                        >
                            <div className="ad-schedule-time">
                                {schedule.start_time} - {schedule.end_time}
                            </div>
                            <div className="ad-schedule-status">Status: {schedule.status}</div>
                            <div className="ad-schedule-duration">
                                Duration: {schedule.duration} giây
                            </div>
                            <div className="ad-schedule-banner">Banner: {schedule.bannerid}</div>
                            {schedule.status === 'running' && (
                                <button onClick={handleViewClick}>View</button>
                            )}
                        </div>
                    ))
                ) : (
                    <div>No running ad schedule available.</div>
                )}
            </div>
        </div>
    );
};

export default AdSchedule;
