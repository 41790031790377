import React from 'react';

const ChannelList = ({ liveChannels, playVideo }) => {
    return (
        <div className="channel-list">
            <h3>Live Channels</h3>
            <div className="channels">
                {liveChannels.map((channel, index) => (
                    <div
                        key={index}
                        className="channel-item"
                        onClick={() => playVideo(channel.content_url)}
                    >
                        <div className="channel-category">{channel.category}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ChannelList;
