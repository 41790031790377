import React from 'react';
import './Campaigns.css';

const Campaigns = () => {
  return (
    <div className="campaigns">
      <h2>Quản lý Chiến Dịch</h2>
      {/* Thêm thông tin Chiến Dịch tại đây */}
    </div>
  );
};

export default Campaigns;
