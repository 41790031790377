import React from 'react';
import './DSP.css';

function DSP() {
  return (
    <div className="dsp">
      <h2>Quản lý DSP</h2>
      <p>Thông tin chi tiết về DSP...</p>
    </div>
  );
}

export default DSP;
