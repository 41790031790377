import React from 'react';

const ZoneSelector = ({ handleZoneChange }) => {
    return (
        <div className="zone-selector">
            <h3>Publisher</h3>
            <button onClick={() => handleZoneChange('53')}>Quốc Hội</button>
            <button onClick={() => handleZoneChange('20')}>ANTV</button>
        </div>
    );
};

export default ZoneSelector;
