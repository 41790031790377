import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
  return (
    <nav className="navbar">
      <ul className="navbar-menu">
        <li><Link to="/adserver">AdServer</Link></li>
        <li><Link to="/ssp">SSP</Link></li>
        <li><Link to="/dsp">DSP</Link></li>
        <li><Link to="/adexchange">AdExchange</Link></li>
        <li><Link to="/advertisers">Advertisers</Link></li>
        <li><Link to="/publishers">Publishers</Link></li>
        <li><Link to="/campaigns">Campaigns</Link></li>
      </ul>
    </nav>
  );
};

export default Sidebar;
