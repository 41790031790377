import React, { useState } from 'react';

const InputForm = ({ handleAddContent }) => {
    const [newContentUrl, setNewContentUrl] = useState('');
    const [newCategory, setNewCategory] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newContentUrl || !newCategory) {
            setMessage('Please provide both content URL and category.');
            return;
        }
        handleAddContent(newContentUrl, newCategory, setMessage);
        setNewContentUrl('');
        setNewCategory('');
    };

    return (
        <div className="add-content-section">
            <h3>Add New Content</h3>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Content URL"
                    value={newContentUrl}
                    onChange={(e) => setNewContentUrl(e.target.value)}
                    required
                />
                <input
                    type="text"
                    placeholder="Category"
                    value={newCategory}
                    onChange={(e) => setNewCategory(e.target.value)}
                    required
                />
                <button type="submit">Add Content</button>
            </form>
            {message && <div className="message">{message}</div>}
        </div>
    );
};

export default InputForm;
