// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  display: flex;
  justify-content: space-around; /* Căn giữa các liên kết */
  background-color: #1a1a1a; /* Màu nền tối */
  padding: 10px 0; /* Padding cho menu */
}

.navbar-menu {
  list-style: none; /* Bỏ dấu chấm */
  display: flex; /* Hiển thị các mục theo hàng ngang */
  margin: 0; /* Bỏ margin */
  padding: 0; /* Bỏ padding */
}

.navbar-menu li {
  margin: 0 15px; /* Khoảng cách giữa các mục */
}

.navbar-menu a {
  color: #ffffff; /* Màu chữ trắng */
  text-decoration: none; /* Bỏ gạch chân */
  padding: 8px 12px; /* Padding cho mỗi liên kết */
  border-radius: 5px; /* Bo góc cho nút */
  transition: background-color 0.3s ease; /* Hiệu ứng chuyển màu nền */
}

.navbar-menu a:hover {
  background-color: #007bff; /* Màu nền khi hover */
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B,EAAE,0BAA0B;EACzD,yBAAyB,EAAE,gBAAgB;EAC3C,eAAe,EAAE,qBAAqB;AACxC;;AAEA;EACE,gBAAgB,EAAE,gBAAgB;EAClC,aAAa,EAAE,qCAAqC;EACpD,SAAS,EAAE,cAAc;EACzB,UAAU,EAAE,eAAe;AAC7B;;AAEA;EACE,cAAc,EAAE,6BAA6B;AAC/C;;AAEA;EACE,cAAc,EAAE,kBAAkB;EAClC,qBAAqB,EAAE,iBAAiB;EACxC,iBAAiB,EAAE,6BAA6B;EAChD,kBAAkB,EAAE,mBAAmB;EACvC,sCAAsC,EAAE,4BAA4B;AACtE;;AAEA;EACE,yBAAyB,EAAE,sBAAsB;AACnD","sourcesContent":[".navbar {\n  display: flex;\n  justify-content: space-around; /* Căn giữa các liên kết */\n  background-color: #1a1a1a; /* Màu nền tối */\n  padding: 10px 0; /* Padding cho menu */\n}\n\n.navbar-menu {\n  list-style: none; /* Bỏ dấu chấm */\n  display: flex; /* Hiển thị các mục theo hàng ngang */\n  margin: 0; /* Bỏ margin */\n  padding: 0; /* Bỏ padding */\n}\n\n.navbar-menu li {\n  margin: 0 15px; /* Khoảng cách giữa các mục */\n}\n\n.navbar-menu a {\n  color: #ffffff; /* Màu chữ trắng */\n  text-decoration: none; /* Bỏ gạch chân */\n  padding: 8px 12px; /* Padding cho mỗi liên kết */\n  border-radius: 5px; /* Bo góc cho nút */\n  transition: background-color 0.3s ease; /* Hiệu ứng chuyển màu nền */\n}\n\n.navbar-menu a:hover {\n  background-color: #007bff; /* Màu nền khi hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
