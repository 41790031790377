import React from 'react';
import './AdExchange.css';

function AdExchange() {
  return (
    <div className="adexchange">
      <h2>Quản lý AdExchange</h2>
      <p>Thông tin chi tiết về AdExchange...</p>
    </div>
  );
}

export default AdExchange;
