import React from 'react';
import './Publishers.css';

const Publishers = () => {
  return (
    <div className="publishers">
      <h2>Quản lý Publishers</h2>
      {/* Thêm thông tin Publishers tại đây */}
    </div>
  );
};

export default Publishers;
