import React, { useRef, useEffect } from 'react';
import Hls from 'hls.js';

const VideoPlayer = ({ currentVideo }) => {
    const playerRef = useRef(null);

    useEffect(() => {
        if (currentVideo) {
            if (Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(currentVideo);
                hls.attachMedia(playerRef.current);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    playerRef.current.play();
                });
            } else if (playerRef.current.canPlayType('application/vnd.apple.mpegurl')) {
                playerRef.current.src = currentVideo;
                playerRef.current.addEventListener('loadedmetadata', () => {
                    playerRef.current.play();
                });
            } else {
                alert('Your browser does not support video playback.');
            }
        }
    }, [currentVideo]);

    return <video ref={playerRef} className="live-video" controls />;
};

export default VideoPlayer;
