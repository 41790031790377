// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Footer.css */
.app-footer {
    background-color: #282c34;
    color: white;
    padding: 10px;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf","sourcesContent":["/* src/components/Footer.css */\n.app-footer {\n    background-color: #282c34;\n    color: white;\n    padding: 10px;\n    text-align: center;\n    position: relative;\n    bottom: 0;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
