import React, { useState, useEffect } from 'react';
import './AdServer.css';
import Header from '../Header/Header'; // Import Header
import Footer from '../Footer/Footer'; // Import Footer
import ChannelList from './ChannelList';
import ZoneSelector from './ZoneSelector';
import AdSchedule from './AdSchedule';
import InputForm from './InputForm';
import VideoPlayer from './VideoPlayer';

const AdServer = () => {
    const [currentVideo, setCurrentVideo] = useState(null);
    const [liveChannels, setLiveChannels] = useState([]);
    const [adSchedule, setAdSchedule] = useState([]);
    const [zoneId, setZoneId] = useState('53');

    useEffect(() => {
        const fetchChannels = async () => {
            try {
                const response = await fetch('https://api-manager.ami.vn/live-channels');
                const data = await response.json();
                if (Array.isArray(data)) {
                    setLiveChannels(data);
                }
            } catch (error) {
                console.error('Error fetching live channels:', error);
            }
        };
        fetchChannels();
    }, []);

    useEffect(() => {
        const fetchAdSchedule = async () => {
            try {
                const response = await fetch(`https://api-manager.ami.vn/schedule?zone_id=${zoneId}`);
                const data = await response.json();
                if (Array.isArray(data)) {
                    setAdSchedule(data.filter(schedule => schedule.status === 'running'));
                }
            } catch (error) {
                console.error('Error fetching ad schedule:', error);
            }
        };
        fetchAdSchedule();
    }, [zoneId]);

    const playVideo = (url) => {
        setCurrentVideo(url);
    };

    const handleViewClick = () => {
        const adVideoUrl = zoneId === '53' 
            ? 'https://ad.ami.vn/Live/quochoi/logo/quochoi.m3u8'
            : 'https://api-ad.ami.vn/live/antv.m3u8';
        playVideo(adVideoUrl);
    };

    const handleZoneChange = (newZoneId) => {
        setZoneId(newZoneId);
        setAdSchedule([]);
    };

    const handleAddContent = async (newContentUrl, newCategory, setMessage) => {
        try {
            const response = await fetch('http://localhost:9001/content', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    content_url: newContentUrl,
                    category: newCategory,
                }),
            });
            if (response.ok) {
                setMessage('Content added successfully!');
            } else {
                const errorMessage = await response.text();
                setMessage(`Error: ${errorMessage}`);
            }
        } catch (error) {
            console.error('Error adding content:', error);
            setMessage('Error adding content.');
        }
    };
return (
    <div className="main-container">
        
        <VideoPlayer currentVideo={currentVideo} />
        <ChannelList liveChannels={liveChannels} playVideo={playVideo} />
        <ZoneSelector handleZoneChange={handleZoneChange} />
        <AdSchedule adSchedule={adSchedule} handleViewClick={handleViewClick} zoneId={zoneId} />
        <InputForm handleAddContent={handleAddContent} />
        
        <Footer /> {/* Thêm Footer ở đây */}
    </div>
);



};

export default AdServer;
