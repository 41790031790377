import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AdProvider } from './contexts/AdContext';
import Sidebar from './components/Sidebar/Sidebar';
import AdServer from './components/AdServer/AdServer';
import SSP from './components/SSP/SSP';
import DSP from './components/DSP/DSP';
import AdExchange from './components/AdExchange/AdExchange';
import Advertisers from './components/Advertisers/Advertisers';
import Publishers from './components/Publishers/Publishers';
import Campaigns from './components/Campaigns/Campaigns';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';

const App = () => {
  return (
    <AdProvider>
      <Router>
        <div className="app">
          <Sidebar />
          <div className="content">
            <Routes>
              <Route path="/" element={<AdServer />} />
              <Route path="/adserver" element={<AdServer />} />
              <Route path="/ssp" element={<SSP />} />
              <Route path="/dsp" element={<DSP />} />
              <Route path="/adexchange" element={<AdExchange />} />
              <Route path="/advertisers" element={<Advertisers />} />
              <Route path="/publishers" element={<Publishers />} />
              <Route path="/campaigns" element={<Campaigns />} />
            </Routes>
          </div>
        </div>
      </Router>
    </AdProvider>
  );
};

export default App;
